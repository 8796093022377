




















import Vue from 'vue';
export default Vue.extend({
  name: 'TitleInfo',
  props: {
    title: String,
    titleStyle: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
});
